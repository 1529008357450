export const urls = [
  {
    imgUrl: "./websites/Hotels-Page.png",
    imgHeight: "-540px",
    transition: "2.8s",
    technologies: "React.js, MongoDB, Express.js, Node.js, Styled-Components",
    name: "DV Hotels",
    githubUrl: "https://github.com/vujic02/DV-Hotels",
    livepageUrl: "https://dv-hotels.netlify.app/",
  },
  {
    imgUrl: "./websites/Easy-Shop.png",
    imgHeight: "-610px",
    transition: "3s",
    technologies: "Next.js, Styled-Components, JSON-Server, Heroku",
    name: "Easy Shop (E-Commerce)",
    githubUrl: "https://github.com/vujic02/Easy-Shop",
    livepageUrl: "https://easy-shop-vujic02.netlify.app/",
  },
  {
    imgUrl: "./websites/Hbo-Go.png",
    imgHeight: "-645px",
    transition: "3s",
    technologies: "React.js, Styled-Components, TMDB API",
    name: "HBO GO Clone",
    githubUrl: "https://github.com/vujic02/HBO-GO-clone",
    livepageUrl: "https://hbo-go-clone.web.app/",
  },
  {
    imgUrl: "./websites/Google-Keep.png",
    imgHeight: "-160px",
    transition: "0.8s",
    technologies: "React.js, Firebase, CSS",
    name: "Google Keep clone",
    githubUrl: "https://github.com/vujic02/google-keep-clone",
    livepageUrl: "https://keep-notes-f3306.web.app/",
  },
  {
    imgUrl: "./websites/Ui-Webpage.png",
    imgHeight: "-1080px",
    transition: "4.8s",
    technologies: "React.js, Styled-Components",
    name: "Simple UI webpage",
    githubUrl: "https://github.com/vujic02/UI-Web-Page",
    livepageUrl: "https://react-ui-webpage.web.app/",
  },
  {
    imgUrl: "./websites/Nasa-Clone.png",
    imgHeight: "-1090px",
    transition: "5s",
    technologies: "HTML, CSS, JavaScript, THREE.js",
    name: "NASA Solar System clone",
    githubUrl: "https://github.com/vujic02/NASA-Planet-Pages-Clone",
    livepageUrl: "https://solar-system-exploration.netlify.app/",
  },
  {
    imgUrl: "./websites/Loplo.png",
    imgHeight: "-360px",
    transition: "2.5s",
    technologies: "Next.js, React.js, Tailwind CSS, Firebase, Youtube Iframe API",
    name: "Loplo",
    githubUrl: "#not-available",
    livepageUrl: "https://loplo.com",
  },
  {
    imgUrl: "./websites/tasks-app-vue.png",
    imgHeight: "0px",
    transition: "2.5s",
    technologies: "Vue.js, Firebase, Tailwind CSS",
    name: "Tasks app",
    githubUrl: "https://github.com/vujic02/tasks-app",
    livepageUrl: "#not-available",
  },
];

export const socialMedia = {
  instagram: "https://www.instagram.com/_vujic02/",
  linkedin: "https://www.linkedin.com/in/nikola-vuji%C4%87/",
  github: "https://github.com/vujic02",
};
